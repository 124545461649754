import React from "react";

import Layout from "../components/layout";

function NotFoundPage() {
    return (
        <Layout>
            <div className="uk-section">
                <div className="uk-container uk-container-medium uk-text-center">
                    <h1>Page not found</h1>
                    <p>
                        Sorry{" "}
                        <span role="img" aria-label="Pensive emoji">
                            😔
                        </span>{" "}
                        we couldn’t find what you were looking for.
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default NotFoundPage;
